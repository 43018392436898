// @ts-nocheck
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import { Link } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import useAuth from "../../hooks/useAuth";
import "./productcard.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useChangeFavStatusMutation } from "../../service/Product";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";

import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import useTranslation from "../../hooks/Translation";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import QuickView from "../QuickView";
import "../Components.scss";

type ProductType = {
  product_id: string;
  product_image: string;
  product_feature: string;
  product_name: string;
  product_price: number;
  location: string;
  wishlist?: boolean;
  booster_applied: boolean;
  favItems?: any[];
  setFavItems?: Dispatch<SetStateAction<any[]>>;
  item?: any;
  loading?: boolean;
  lang?: number;
  weeklyDeals?: boolean;
  routePath: any;
};

const ProductCard = ({
  product_id,
  product_image,
  product_feature,
  product_name,
  product_price,
  location,
  wishlist,
  favItems,
  setFavItems,
  booster_applied,
  item,
  loading,
  lang,
  weeklyDeals,
  routePath,
}: ProductType) => {
  const user = useAuth();
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const [changeFavStatus] = useChangeFavStatusMutation();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<boolean | undefined>(false);

  const [selectedImage, setSelectedImage] = useState(item?.images[0] || "");

  const [translatedProductName, setTranslatedProductName] = useState("");

  const targetLanguage = language === "ar" ? "ar" : "en";

  // const handleTranslate = async () => {
  //   if (product_name) {
  //     const translatedText = await GoogleTranslateScript(
  //       product_name,
  //       targetLanguage
  //     );
  //     setTranslatedProductName(translatedText);
  //   }
  // };
  // useEffect(() => {
  //   handleTranslate();
  // }, [language]);

  useEffect(() => {
    setSelected(wishlist);
  }, [wishlist]);

  const handleFav = async (e: any, item: RecommendedProductsData) => {
    e.preventDefault();
    e.stopPropagation();
    // if (token) {
    const data = {
      productId: item?._id,
      isFav: favItems?.includes(item) ? false : true,
    };

    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    try {
      const response = await changeFavStatus(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        if (favItems?.includes(item)) {
          let indexToDelete = favItems.indexOf(item);
          favItems.splice(indexToDelete, 1);
          setFavItems([...favItems]);
          successToast(response?.message || "");
        } else {
          favItems?.push(item);
          setFavItems([...favItems]);
          successToast(response?.message || "");
        }
      } else {
        errorToast(translation.toast_messages.try_again);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenQuickView = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <Link to={routePath} className="prdct_crd">
        <div>
          <figure className="prdct_img">
            <figure className="zoom_icon" onClick={handleOpenQuickView}>
              <ZoomInIcon />
            </figure>
            <LazyLoadImage
              alt="product_image"
              src={
                product_image?.length
                  ? product_image
                  : "/static/images/logo.png"
              }
              effect="blur"
              height={"150px"}
              width={"100%"}
            />

            {booster_applied ? (
              <div className="boosterIcon">
                <figure>
                  <LazyLoadImage
                    alt="booster"
                    src={"/static/images/booster.png"}
                    effect="blur"
                    height={"100%"}
                    width={"100%"}
                  />
                </figure>
              </div>
            ) : null}
            {weeklyDeals ? (
              <div className="wkly_dls">
                <h6>{translation.Globals.weekly_deals || ""}</h6>
              </div>
            ) : null}

            {user?._id === item?.userId ? null : (
              <div className="fvrt_img">
                {favItems?.includes(item) ? (
                  <FavoriteIcon onClick={(e) => handleFav(e, item)} />
                ) : (
                  <FavoriteBorderRoundedIcon
                    onClick={(e) => handleFav(e, item)}
                  />
                )}
              </div>
            )}
          </figure>
          <div className="prdct_dtl">
            {product_price === 0 || !product_price ? (
              <h4>{translation.Globals.askPrice || ""}</h4>
            ) : (
              <h4>
                {translation.Globals.aed}{" "}
                {product_price.toLocaleString("en-IN") || 0}
              </h4>
            )}

            <h3>
              {product_name?.length > 35
                ? product_name.slice(0, 35) + "...."
                : product_name || ""}
            </h3>
            {/* <h3>
              {language === "en" ? (
                <>{lang === 1 ? product_name : translatedProductName}</>
              ) : (
                <>{lang === 2 ? product_name : translatedProductName}</>
              )}{" "}
            </h3> */}

            <h4>{product_feature || ""}</h4>
          </div>
        </div>
      </Link>
      <QuickView
        open={open}
        setOpen={setOpen}
        item={item}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
    </>
  );
};

export default ProductCard;
