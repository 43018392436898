import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Modal,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Dispatch, Key, SetStateAction, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { STORAGE_KEYS, getFromStorage } from "../../helpers";
import { format } from "date-fns";
import useTranslation from "../../hooks/Translation";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./QuickView.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  borderRadius: 2,
  outline: "none",
};

const magnifierHeight = 350;
const magnifierWidth = 440;
const zoomLevel = 2;

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  item?: any;
  selectedImage: string;
  setSelectedImage: Dispatch<SetStateAction<string>>;
};

const QuickView = ({
  open,
  setOpen,
  item,
  selectedImage,
  setSelectedImage,
}: props) => {
  const navigate = useNavigate();
  const translation = useTranslation() as any;
  const language = getFromStorage(STORAGE_KEYS.language);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [expanded, setExpanded] = useState<string | boolean>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      hideBackdrop
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="quick_view">
          <div className="cross ">
            <CloseIcon
              onClick={() => {
                setOpen(false);
                setExpanded("panel1");
              }}
            />
          </div>
          <h2 className="quickproduct_name">{item?.name || "-"}</h2>
          <div className="quick_view_detail">
            <div className="dLeft">
              <div className="prdct_img_sc web_images">
                <div
                  style={{
                    position: "relative",
                    height: 500,
                    width: 440,
                  }}
                >
                  <img
                    src={selectedImage}
                    style={{ height: 500, width: 440 }}
                    alt={"img"}
                    onMouseEnter={(e) => {
                      const elem = e.currentTarget;
                      const { width, height } = elem.getBoundingClientRect();
                      setSize([width, height]);
                      setShowMagnifier(true);
                    }}
                    onMouseMove={(e) => {
                      const elem = e.currentTarget;
                      const { top, left } = elem.getBoundingClientRect();
                      const x = e.pageX - left - window.pageXOffset;
                      const y = e.pageY - top - window.pageYOffset;
                      setXY([x, y]);
                    }}
                    onMouseLeave={() => {
                      setShowMagnifier(false);
                    }}
                  />
                  <div
                    className="magnifier_show"
                    style={{
                      display: showMagnifier ? "" : "none",
                      position: "absolute",
                      pointerEvents: "none",
                      height: `${magnifierHeight}px`,
                      width: `${magnifierWidth}px`,
                      top: `${y - magnifierHeight / 2}px`,
                      left: `${x - magnifierWidth / 2}px`,
                      opacity: "1",
                      border: "1px solid lightgray",
                      backgroundColor: "white",
                      backgroundImage: `url('${selectedImage}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: `${imgWidth * zoomLevel}px ${
                        imgHeight * zoomLevel
                      }px`,
                      backgroundPositionX: `${
                        -x * zoomLevel + magnifierWidth / 2
                      }px`,
                      backgroundPositionY: `${
                        -y * zoomLevel + magnifierHeight / 2
                      }px`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="p_Imgs">
                {item?.images?.length
                  ? item?.images?.map((val: string, i: Key | null) => (
                      <figure onClick={() => handleImageClick(val)} key={i}>
                        <LazyLoadImage
                          alt="Product"
                          src={val}
                          effect="blur"
                          height={"100%"}
                          width={"100%"}
                        />
                      </figure>
                    ))
                  : null}
              </div>
            </div>

            <div className="dRyt">
              <div className="rightCard">
                <Accordion
                  className="quic_vw_ac"
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      {translation?.Globals?.details || ""}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <h3
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      {language === "ar"
                        ? item?.category?.name_ar || ""
                        : item?.category?.name || ""}
                      <figure
                        style={{
                          height: "40px",
                          width: "40px",
                        }}
                      >
                        {item?.boosterApplied ? (
                          <LazyLoadImage
                            alt="booster"
                            src={"/static/images/booster.png"}
                            effect="blur"
                            height={"100%"}
                            width={"100%"}
                          />
                        ) : null}
                      </figure>
                    </h3>
                    {item?.createdAt ? (
                      <p className="p2">
                        {translation.product.posted_on}:{" "}
                        {item?.createdAt
                          ? format(new Date(item.createdAt), "MMM do yyyy")
                          : ""}
                      </p>
                    ) : null}

                    {item?.price === 0 || !item?.price ? (
                      <h4 style={{ color: "#820000", paddingTop: "6px" }}>
                        {translation.Globals.askPrice || ""}
                      </h4>
                    ) : (
                      <h4 style={{ color: "#820000", paddingTop: "6px" }}>
                        {translation.Globals.aed}{" "}
                        {item?.price.toLocaleString("en-IN") || 0}
                      </h4>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className="quic_vw_ac"
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>
                      {translation?.profile?.description || ""}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p style={{ lineHeight: "24px", wordBreak: "break-all" }}>
                      {item?.description || ""}
                    </p>
                  </AccordionDetails>
                </Accordion>
              </div>
              <Button
                value={translation.Globals.product_page}
                onClick={() => navigate(`/product-detail/${item?._id}`)}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default QuickView;
