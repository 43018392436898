// @ts-nocheck
import React from "react";
import "../Components.scss";

import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import useTranslation from "../../hooks/Translation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { toggleAuthModal } from "../../reducers/generalSlice";
import { useAppDispatch } from "../../hooks/store";

type ProductType = {
  product_id: string;
  product_image: string;
  product_name: string;
  product_price: number;
};

const SellerCard = ({
  product_id,
  product_image,
  product_name,
}: ProductType) => {
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const translation = useTranslation() as any;

  const handleVisitSeller = (id) => {
    if (user) {
      navigate(`/view-profile/${id}`);
    } else {
      dispatch(
        toggleAuthModal({ isAuthModalVisible: true, ModalType: "login" })
      );
    }
  };

  return (
    <>
      <div onClick={() => handleVisitSeller(product_id)} className="prdct_crd ">
        <div>
          <figure className="prdct_img">
            <LazyLoadImage
              alt="product_image"
              src={
                product_image?.length
                  ? product_image
                  : "/static/images/logo.png"
              }
              effect="blur"
              height={"100%"}
              width={"100%"}
            />
          </figure>
          <div className="prdct_dtl">
            <h3>
              {product_name?.length > 35
                ? product_name.slice(0, 35) + "...."
                : product_name || ""}
            </h3>
            <h6
              style={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#0101af",
              }}
              onClick={() => handleVisitSeller(product_id)}
            >
              {translation.Globals.visit_store}{" "}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellerCard;
