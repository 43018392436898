import React, { useEffect } from "react";
import { Typography, Breadcrumbs } from "@mui/material";
import useTranslation from "../../hooks/Translation";
import { Link, useNavigate } from "react-router-dom";
import "./breadcrumbs.scss";

const BreadcrumbComponent = () => {
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const pageLocation = window.location.pathname;
  const [path, setPath] = React.useState("");

  const urlParams = new URLSearchParams(window.location.search);

  const cat_id = urlParams.get("cat_id") || "";

  const name_en = decodeURIComponent(urlParams.get("name_en") || "");

  // const [translatedPath, setTranslatedPath] = useState("");

  // const targetLanguage = language === "ar" ? "ar" : "en";

  const formatRouteName = (route: string) => {
    return route
      .replace(/-/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const handleBreadCrumbClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (pageLocation) {
      if (
        pageLocation.startsWith("/products/") ||
        pageLocation.startsWith("/product-detail/")
      ) {
        if (name_en === "featured") {
          setPath("Featured Products");
        } else if (name_en === "recommended") {
          setPath("Recommended Products");
        } else if (name_en === "arrival") {
          setPath("New Arrivals");
        } else if (name_en === "explore") {
          setPath("Explore Products");
        } else if (name_en === "recent") {
          setPath("Recently Viewed");
        } else if (name_en === "search") {
          setPath("Mostly Search");
        } else if (name_en === "view") {
          setPath("Mostly Viewed");
        } else if (name_en === "top-offers") {
          setPath("Top Offers");
        } else if (name_en === "hot-deals") {
          setPath("Hot Deals");
        } else {
          setPath(name_en?.toLowerCase() || "");
        }
      } else if (pageLocation.startsWith("/all-products/")) {
        const segments = pageLocation.split("/");
        const pageName = segments[2];

        if (pageName === "featured") {
          setPath("Featured Products");
        } else if (pageName === "recommended") {
          setPath("Recommended Products");
        } else if (pageName === "arrival") {
          setPath("New Arrivals");
        } else if (pageName === "explore") {
          setPath("Explore Products");
        } else if (pageName === "recent") {
          setPath("Recently Viewed");
        } else if (pageName === "search") {
          setPath("Mostly Search");
        } else if (pageName === "view") {
          setPath("Mostly Viewed");
        } else if (pageName === "top-offers") {
          setPath("Top Offers");
        } else if (pageName === "hot-deals") {
          setPath("Hot Deals");
        }
      } else {
        const route = pageLocation.replace("/", "");
        const name = route.split("/");
        let newName = "";
        if (name?.length > 1) {
          newName = formatRouteName(name.slice(0, -1).join(" "));
        } else {
          newName = formatRouteName(route);
        }
        setPath(newName);
      }
    }
  }, [pageLocation]);

  // const handleTranslate = async () => {
  //   if (path) {
  //     const translatedLanguage = await GoogleTranslateScript(
  //       path,
  //       targetLanguage
  //     );
  //     setTranslatedPath(translatedLanguage);
  //   }
  // };
  // useEffect(() => {
  //   handleTranslate();
  // }, [language, path]);

  return (
    <div className={pageLocation === "/" ? "no_brdcrmbs" : "mn_brdcrmbs"}>
      <div className="container">
        <div
          role="presentation"
          onClick={(e) => handleBreadCrumbClick(e)}
          style={{
            padding: "30px 0",

            zIndex: 1,
            cursor: "pointer",
          }}
        >
          <Breadcrumbs
            key="1"
            aria-label="breadcrumb"
            separator={path === "" ? "" : "›"}
          >
            <div
              onClick={() => {
                navigate("/");
              }}
              className={"brdcrmb"}
            >
              {translation.header.home || ""}
            </div>

            {pageLocation.startsWith("/products/") ? (
              <Typography
                key="2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#820000",
                  textTransform: "capitalize",
                }}
                color="text.primary"
              >
                {path}
              </Typography>
            ) : pageLocation.startsWith("/all-products/") ? (
              <Typography
                key="2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#820000",
                  textTransform: "capitalize",
                }}
                color="text.primary"
              >
                {path}
              </Typography>
            ) : pageLocation.startsWith("/product-detail/") ? (
              !cat_id && !name_en ? null : cat_id ? (
                <Link
                  to={{
                    pathname: `/products/${cat_id}`,
                    search: `?name_ar=${""}&name_en=${encodeURIComponent(
                      name_en
                    )}&subCat=${""}&sort=${""}&minPrice=${0}&maxPrice=${1000000}&page=${1}`,
                  }}
                >
                  <Typography
                    key="2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "#820000",
                      textTransform: "capitalize",
                    }}
                    color="text.primary"
                  >
                    {path}
                  </Typography>
                </Link>
              ) : name_en === "wishlist" ? (
                <Typography
                  key="2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#820000",
                    textTransform: "capitalize",
                  }}
                  color="text.primary"
                  onClick={() => navigate("/wishlist")}
                >
                  {path}
                </Typography>
              ) : (
                <Link to={`/all-products/${name_en}`}>
                  <Typography
                    key="2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "#820000",
                      textTransform: "capitalize",
                    }}
                    color="text.primary"
                  >
                    {path}
                  </Typography>
                  {/* </Link> */}
                </Link>
              )
            ) : (
              <Typography
                key="2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#820000",
                  textTransform: "capitalize",
                }}
                color="text.primary"
              >
                {path}
              </Typography>
            )}
            {pageLocation.startsWith("/product-detail/") ? (
              <Typography
                key="2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#820000",
                  textTransform: "capitalize",
                }}
                color="text.primary"
              >
                {"Product Detail"}
              </Typography>
            ) : null}
          </Breadcrumbs>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbComponent;
