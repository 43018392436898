import React from "react";
import { CircularProgress } from "@mui/material";
import "./fallback.scss";

const Fallback = () => {
  return (
    <div className="fallback">
      <figure>
        <img
          src="/static/images/fallback.webp"
          alt="Loading"
          rel={"preload"}
          fetchPriority={"high"}
          loading={"eager"}
        />
      </figure>
      <CircularProgress
        sx={{
          color: "#F5F7F8",
        }}
      />
    </div>
  );
};

export default Fallback;
