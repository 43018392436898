import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./BlogsCard.scss";
import useTranslation from "../../hooks/Translation";

type blogData = {
  image: string;
  date: string;
  author: string;
  heading: string;
  blogId: number;
  cat: number;
  description: string;
};

const BlogCard = ({
  image,
  date,
  author,
  heading,
  blogId,
  cat,
  description,
}: blogData) => {
  const translation = useTranslation() as any;
  return (
    <div className="blog_crd">
      <a href={`/blog-detail/${blogId}`}>
        <figure>
          <LazyLoadImage
            alt="Blog Image"
            src={image}
            effect="blur"
            height={"100%"}
            width={"100%"}
          />
        </figure>
        <div className="blg_dt">
          <p>{date}</p>
          <h5>{author}</h5>
        </div>
        <h3 dangerouslySetInnerHTML={{ __html: heading }} />
        <p dangerouslySetInnerHTML={{ __html: description }} />
        <a>
          <span>{translation.Globals.read_more || ""}</span>
        </a>
      </a>
    </div>
  );
};

export default BlogCard;
