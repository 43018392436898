import { Skeleton } from "@mui/material";
import "./productcardskeleton.scss";

const ProductCardSkeleton = () => {
  return (
    <div className="whlt_sc prdct_crd_sk">
      <figure className="prdct_img">
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"100%"}
          height={"100%"}
        />
      </figure>
      <div className="prdct_dtl">
        <h4>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={"70px"}
            animation="wave"
          />
        </h4>

        <h3>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={"90px"}
            animation="wave"
          />
        </h3>
        <h4>
          {" "}
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={"110px"}
            animation="wave"
          />
        </h4>
      </div>

      <p>
        {" "}
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation="wave" />
      </p>
    </div>
  );
};

export default ProductCardSkeleton;
