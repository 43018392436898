import axios from "axios";
import { GOOGLE_API_KEY } from "../helpers/constants/urls";

const API_URL = "https://translation.googleapis.com/language/translate/v2";

const GoogleTranslateScript = async (inputArray, targetLanguage) => {
  try {
    const response = await axios.post(`${API_URL}?key=${GOOGLE_API_KEY}`, {
      q: inputArray,
      target: targetLanguage,
    });

    return response.data.data.translations[0].translatedText;
  } catch (error) {
    console.error("Error translating text:", error);
    throw error;
  }
};
const translateArray = async (inputArray, targetLanguage) => {
  try {
    const translatedArray = [];

    for (const item of inputArray) {
      const translatedItem = {};

      for (const key in item) {
        const keyTranslation = await GoogleTranslateScript(key, targetLanguage);
        const valueTranslation = await GoogleTranslateScript(
          item[key],
          targetLanguage
        );

        translatedItem[keyTranslation] = valueTranslation;
      }

      translatedArray.push(translatedItem);
    }

    return translatedArray;
  } catch (error) {
    console.error("Error translating array:", error);
    throw error;
  }
};

export { GoogleTranslateScript, translateArray };
