import { Route, Routes } from "react-router-dom";
// import Pages from "./pages";
import ProtectedRoutes from "./utils/protectedRoutes";
import { Suspense, lazy, startTransition, useEffect, useState } from "react";
import { Fallback } from "./components";

const Routing = () => {
  const [isTransitioning, setIsTransitioning] = useState(true);

  const HomePage = lazy(() => import("./pages/home"));
  const ChooseCategory = lazy(() => import("./pages/chooseCategory"));
  const Wishlist = lazy(() => import("./pages/wishlist"));
  const Profile = lazy(() => import("./pages/profile"));
  const AddProduct = lazy(() => import("./pages/addProduct"));
  const Chat = lazy(() => import("./pages/chat"));
  const Filters = lazy(() => import("./pages/filters"));
  const Notifications = lazy(() => import("./pages/notifications"));
  const ProductDetail = lazy(() => import("./pages/productDetail"));
  const SeeAll = lazy(() => import("./pages/seeAll"));
  const ContactUs = lazy(() => import("./pages/contactUs"));
  const AboutUs = lazy(() => import("./pages/aboutUs"));
  const Faqs = lazy(() => import("./pages/faq"));
  const PrivacyPolicy = lazy(() => import("./pages/privacyPolicy"));
  const TermsConditions = lazy(() => import("./pages/terms&Conditions"));
  const ConsumerRights = lazy(() => import("./pages/consumerRights"));
  const ServerMaintainance = lazy(() => import("./pages/serverMaintainance"));
  const OfficialStores = lazy(() => import("./pages/officialStores"));
  const ViewProfile = lazy(() => import("./pages/viewProfile"));
  const SearchedProducts = lazy(() => import("./pages/searchedProducts"));
  const Blogs = lazy(() => import("./pages/blog"));
  const SeasonalOffers = lazy(() => import("./pages/seasonalOffers"));
  const Sell = lazy(() => import("./pages/sell"));
  const WeeklyDeals = lazy(() => import("./pages/weeklyDeals"));
  const AllBlogs = lazy(() => import("./pages/allBlogs"));
  const BlogDetail = lazy(() => import("./pages/blogDetail"));
  const TopManufacturers = lazy(() => import("./pages/top-manufacturers"));

  useEffect(() => {
    startTransition(() => {
      setIsTransitioning(false);
    });
  }, []);

  return (
    <div>
      {isTransitioning ? (
        <Fallback />
      ) : (
        <Suspense fallback={<Fallback />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/choose-category"
              element={
                <ProtectedRoutes>
                  <ChooseCategory />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/wishlist"
              element={
                <ProtectedRoutes>
                  <Wishlist />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoutes>
                  <Profile />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/add-product/:id"
              element={
                <ProtectedRoutes>
                  <AddProduct />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/add-product/:id/:prodId"
              element={
                <ProtectedRoutes>
                  <AddProduct />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/chat"
              element={
                <ProtectedRoutes>
                  <Chat />
                </ProtectedRoutes>
              }
            />
            <Route path="/products/:id" element={<Filters />} />
            <Route
              path="/notifications"
              element={
                <ProtectedRoutes>
                  <Notifications />
                </ProtectedRoutes>
              }
            />

            <Route path="/product-detail/:id" element={<ProductDetail />} />
            <Route path="/all-products/:name" element={<SeeAll />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
            <Route path="/consumer-rights" element={<ConsumerRights />} />
            <Route
              path="/server-maintenance"
              element={<ServerMaintainance />}
            />
            <Route path="/official-stores" element={<OfficialStores />} />

            <Route
              path="/view-profile/:id"
              element={
                <ProtectedRoutes>
                  <ViewProfile />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/searched-products/:search"
              element={<SearchedProducts />}
            />
            <Route path="/blog" element={<Blogs />} />
            <Route path="/seasonal-offers" element={<SeasonalOffers />} />
            <Route path="/sell" element={<Sell />} />
            <Route path="/weekly-deals/:id" element={<WeeklyDeals />} />
            <Route path="/all-blogs/:id" element={<AllBlogs />} />
            <Route path="/blog-detail/:id" element={<BlogDetail />} />
            <Route path="/top-manufacturers" element={<TopManufacturers />} />
          </Routes>
        </Suspense>
      )}
    </div>
  );
};
export default Routing;
